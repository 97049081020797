// extracted by mini-css-extract-plugin
export var Display1 = "grafik-module--Display1--iMTZj";
export var blob1 = "grafik-module--blob1--hhQRN";
export var blob2 = "grafik-module--blob2--eNXKu";
export var blob3 = "grafik-module--blob3--GivHY";
export var blob4 = "grafik-module--blob4--Kla7R";
export var cover = "grafik-module--cover--mRQLS";
export var dotPattern1 = "grafik-module--dotPattern1--tOvsM";
export var dotPattern2 = "grafik-module--dotPattern2--eUVsB";
export var pageNumber = "grafik-module--pageNumber--PpV8w";
export var phone = "grafik-module--phone--vaAqF";
export var text = "grafik-module--text--jaE2V";