import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Phone from "../assets/phone.inline.svg"
import BlobGradient from "../assets/blobWithGradient1.inline.svg"
import BlobClip from "../assets/clipBlob.inline.svg"
import Blob from "../assets/blob.inline.svg"
import * as grafikStyles from "../styles/grafik.module.css"
import Dotpattern from "../assets/dot_pattern.inline.svg"
import SwipeDown from "../assets/swipe_down_anim.inline.svg"
import ScrollDown from "../assets/scroll_down_anim.inline.svg"
import NextArrow from "../components/nextArrow"
import PreviousArrow from "../components/previousArrow"
import LanguageSwitch from "../components/languageSwitch"
import ThemeSwitch from "../components/themeSwitch"
import SoundButton from "../components/soundToggle"
import { gsap } from "gsap/dist/gsap"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import Page04 from "../assets/page04.inline.svg"
import Seo from "../components/seo"
import { usePrefersReducedMotion } from "../components/prefersReducedMotion"
import {
  floating,
  swipeDown,
  scrollDownMouse,
} from "../components/GSAPanimations"
import useDeviceDetect from "../components/deviceDetect"
import ScrollTopArrow from "../components/backToTop"

const Grafik = ({ location }) => {
  const { language } = useI18next()
  const prefersReducedMotion = usePrefersReducedMotion()
  const { isMobile } = useDeviceDetect()

  useEffect(() => {
    let scrollStorage = window.localStorage.getItem("scroll")
    if (scrollStorage === null) {
      scrollStorage = 1
    } else if (scrollStorage < 20) {
      scrollStorage = JSON.parse(scrollStorage) + 1
    }
    window.localStorage.setItem("scroll", scrollStorage)
    const hand1 = document.querySelector("#hand1")
    const arrow1 = document.querySelector("#arrow1")
    const mouse = document.querySelector("#mouse")
    const scrollWheel = document.querySelector("#scrollWheel")
    const arrow2 = document.querySelector("#arrow2")
    const swipeDownAnim = document.querySelector("#swipeDownAnim")
    const ScrollDownAnim = document.querySelector("#ScrollDownAnim")
    if (isMobile === undefined) return
    if (isMobile && scrollStorage < 6) {
      swipeDown(hand1, arrow1, swipeDownAnim)
    } else if (scrollStorage < 6) {
      scrollDownMouse(mouse, scrollWheel, arrow2, ScrollDownAnim)
    }
  }, [isMobile])

  useEffect(() => {
    const blob1 = document.querySelector("[class*='blob1']")
    const blob2 = document.querySelector("[class*='blob2']")
    const blob3 = document.querySelector("[class*='cover']")
    const blob4 = document.querySelector("[class*='blob4']")
    const pageNum = document.querySelector("[class*='pageNum']")
    const dot1 = document.querySelector("[class*='dotPattern1']")
    const dot2 = document.querySelector("[class*='dotPattern2']")
    gsap.killTweensOf(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)
    document.querySelector("#phoneShade").style.transform = ""
    if (prefersReducedMotion === true) {
      let show = document.querySelectorAll(
        "#logo, #ScreenOverlay, #screenColor, #screenReflection, #phoneEdgeColor, #notchColors, #buttonColor, #rearButtons, #speaker, #chargeVentColor,#sideColor,#phoneFrontColor,#cameraLens, #phoneShade"
      )
      show.forEach(item => {
        item.style.visibility = "visible"
      })
      document.querySelector("#phoneShade").style.transform = "translateY(10px)"
      return
    }
    floating(blob1, blob2, blob3, blob4, pageNum, dot1, dot2)

    const phoneBuild = gsap.timeline({
      repeat: -1,
      repeatDelay: 2,
      delay: 1,
      immediateRender: true,
    })

    phoneBuild.set("#mobileGuides > path", {
      attr: {
        "stroke-dasharray": function (index, target, targets) {
          //function-based value
          return target.getTotalLength()
        },
        "stroke-dashoffset": function (index, target, targets) {
          //function-based value
          return target.getTotalLength()
        },
      },
    })
    phoneBuild.set("#logoGuides > path", {
      attr: {
        "stroke-dasharray": function (index, target, targets) {
          //function-based value
          return target.getTotalLength()
        },
        "stroke-dashoffset": function (index, target, targets) {
          //function-based value
          return target.getTotalLength()
        },
      },
    })
    phoneBuild.set("#mobileGuides, #logoGuides", { autoAlpha: 1 })
    phoneBuild.to("#mobileGuides > path", {
      attr: { "stroke-dashoffset": 0 },
      stagger: 0.05,
      duration: 0.3,
    })
    phoneBuild.to(
      "#phoneOutline",
      { duration: 3, autoAlpha: 1, ease: "power2.out" },
      ">"
    )
    phoneBuild.to(
      "#ScreenOverlay,#screenColor",
      { duration: 2, autoAlpha: 1, ease: "power2.out" },
      ">+0.8"
    )
    phoneBuild.to(
      "#logoGuides > path",
      {
        attr: { "stroke-dashoffset": 0 },
        stagger: 0.1,
        duration: 0.3,
      },
      ">"
    )
    phoneBuild.to(
      "#logoGlow",
      { duration: 1.1, autoAlpha: 1, ease: "power2.out" },
      ">+1"
    )
    phoneBuild.to(
      "#logoGlow",
      { duration: 0.4, autoAlpha: 0, ease: "power2.out" },
      ">"
    )
    phoneBuild.to(
      "#logo",
      { duration: 4, autoAlpha: 1, ease: "power2.out" },
      "<"
    )
    phoneBuild.to(
      "#mobileGuides, #logoGuides",
      { duration: 3, autoAlpha: 0, ease: "power2.out" },
      "<"
    )
    phoneBuild.to(
      "#phoneOutline",
      { duration: 1, autoAlpha: 0, ease: "power2.out" },
      ">-2"
    )
    phoneBuild.to(
      "#phone",
      { duration: 10, y: "-=25", ease: "elastic.out(1, 0.3)" },
      "<-0.8"
    )
    phoneBuild.to(
      "#screenReflection,#phoneEdgeColor, #notchColors, #buttonColor, #rearButtons, #speaker, #chargeVentColor,#sideColor,#phoneFrontColor,#cameraLens, #phoneShade",
      { duration: 0.6, autoAlpha: 1, ease: "power2.out" },
      "<"
    )
    phoneBuild.to(
      "#phoneShade",
      { duration: 1.3, scale: 1.1, transformOrigin: "50% 50%", ease: "none" },
      "<"
    )
    phoneBuild.to(
      "#shadeBlur",
      { duration: 0.8, attr: { stdDeviation: 5.28 }, ease: "none" },
      "<"
    )
    phoneBuild.to(
      "#phone",
      { duration: 6, y: "+=8", ease: "none", yoyo: true, repeat: 10 },
      ">"
    )
    phoneBuild.to(
      "#phoneShade",
      {
        duration: 6,
        scale: 1.08,
        transformOrigin: "50% 50%",
        ease: "none",
        yoyo: true,
        repeat: 10,
      },
      "<"
    )
    phoneBuild.to(
      "#shadeBlur",
      {
        duration: 6,
        attr: { stdDeviation: 4.68 },
        ease: "none",
        yoyo: true,
        repeat: 10,
      },
      "<"
    )
    phoneBuild.to(
      "#phoneEdgeColor, #notchColors, #buttonColor, #rearButtons, #speaker, #chargeVentColor, #screenReflection, #ScreenOverlay, #screenColor, #sideColor, #phoneFrontColor,#cameraLens, #logo, #phoneShade",
      { duration: 4, autoAlpha: 0, ease: "power2.out" },
      ">"
    )
    phoneBuild.set(
      "#pictureFilter > feGaussianBlur",
      { attr: { stdDeviation: 0 } },
      ">"
    )
    phoneBuild.set("#phone", { y: "+=21" }, ">")
    phoneBuild.timeScale(3.8)
  }, [prefersReducedMotion])

  const { t } = useTranslation()

  return (
    <>
      <Seo
        title={t("GRAFIK.SEO_TITLE")}
        description={t("GRAFIK.SEO_DESCRIPTION")}
        pathName={location.pathname}
        image="SoMe/open_graph_phone.png"
        readingTime="1"
      />
      <PreviousArrow
        to={language === "da" ? `/webudvikling/` : `/${language}/webudvikling/`}
      />
      <NextArrow
        to={language === "da" ? `/kompetencer/` : `/${language}/kompetencer/`}
      />
      <main className="bodyWrapper">
        <LanguageSwitch />
        <SoundButton />
        <ThemeSwitch />
        <SwipeDown />
        <ScrollDown />
        <section className="card">
          <Page04 className={grafikStyles.pageNumber} />
          <h1 className={grafikStyles.Display1}>{t("GRAFIK.DISPLAY")}</h1>
          <h2>{t("GRAFIK.HEADLINE")}</h2>
          <div className={grafikStyles.text}>
            <span style={{ display: "inline" }}>
              <p>{t("GRAFIK.BODYTEXT_ONE")}</p>
              <a
                href="https://inkscape.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Inkscape
              </a>
              <p>{t("GRAFIK.BODYTEXT_TWO")}</p>
              <a
                href="https://www.blender.org/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Blender
              </a>
              <p>{t("GRAFIK.BODYTEXT_THREE")}</p>
              <a
                href="https://greensock.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                GSAP
              </a>
              <p>{t("GRAFIK.BODYTEXT_FOUR")}</p>
            </span>
          </div>
          <BlobGradient className={grafikStyles.blob1} />
          <Dotpattern className={grafikStyles.dotPattern1} />
        </section>
        <section className="card right">
          <Blob className={grafikStyles.blob2} />
          <Phone className={grafikStyles.phone} />
          <BlobClip className={grafikStyles.blob3} />
          <div className={grafikStyles.cover} />
          <Blob className={grafikStyles.blob4} />
          <Dotpattern className={grafikStyles.dotPattern2} />
        </section>
        <section
          className="card"
          style={{ padding: 0, width: "100%", height: "fit-content" }}
        >
          <h2>{t("GRAFIK.HEADER_INKSCAPE")}</h2>
          <StaticImage
            alt={t("GRAFIK.FAQ")}
            src="../images/illustrations/FAQ.png"
            placeholder="blurred"
            layout="constrained"
            width={1000}
            loading={isMobile ? "lazy" : "eager"}
            style={{ maxWidth: "1000px", zIndex: 2, margin: "16px auto" }}
          />
        </section>
        <section
          className="card right"
          style={{ padding: 0, width: "100%", height: "fit-content" }}
        >
          <StaticImage
            alt={t("GRAFIK.SECURITY")}
            src="../images/illustrations/security.png"
            placeholder="blurred"
            layout="constrained"
            width={1000}
            loading={isMobile ? "lazy" : "eager"}
            style={{
              width: "100%",
              padding: "0",
              zIndex: 2,
              margin: "16px auto",
            }}
          />
        </section>
        <section
          className="card wide"
          style={{
            padding: "0",
            width: "100%",
            height: "fit-content",
            overflow: "visible",
          }}
        >
          <StaticImage
            alt={t("GRAFIK.TOOLBOX")}
            src="../images/illustrations/toolbox.png"
            placeholder="blurred"
            layout="constrained"
            width={1000}
            loading="lazy"
            style={{
              maxWidth: "1000px",
              zIndex: 2,
              margin: "0 auto",
              display: "block",
            }}
          />
        </section>
        <section
          className="card"
          style={{
            padding: 0,
            width: "100%",
            height: "fit-content",
            margin: "0 auto",
            maxWidth: "520px",
          }}
        >
          <StaticImage
            alt={t("GRAFIK.KEYBOARD_MOUSE")}
            src="../images/illustrations/keyboard_mouse.png"
            placeholder="blurred"
            layout="constrained"
            width={520}
            loading="lazy"
            style={{ maxWidth: "520px", zIndex: "2" }}
          />
        </section>
        <section
          className="card right"
          style={{
            padding: 0,
            width: "100%",
            height: "fit-content",
            margin: "0 auto",
            maxWidth: "520px",
          }}
        >
          <StaticImage
            alt={t("GRAFIK.MOVING_GEARS")}
            src="../images/illustrations/moving_gears.png"
            placeholder="blurred"
            layout="constrained"
            width={520}
            loading="lazy"
            style={{ maxWidth: "520px", zIndex: "2" }}
          />
        </section>
        <section
          className="card wide"
          style={{ padding: 0, width: "100%", height: "fit-content" }}
        >
          <StaticImage
            alt={t("GRAFIK.TALKING_CHARACTERS")}
            src="../images/illustrations/talking_characters.png"
            placeholder="blurred"
            layout="constrained"
            width={1000}
            loading="lazy"
            style={{
              maxWidth: "1000px",
              zIndex: "2",
              margin: "16px auto",
              display: "block",
            }}
          />
        </section>
        <section
          className="card"
          style={{
            padding: 0,
            width: "60%",
            height: "fit-content",
            margin: "0 auto",
          }}
        >
          <StaticImage
            alt={t("GRAFIK.NO_PHONE")}
            src="../images/illustrations/no_phone.png"
            placeholder="blurred"
            layout="constrained"
            width={515}
            loading="lazy"
            style={{ display: "block" }}
          />
        </section>
        <section
          className="card right"
          style={{
            padding: 0,
            width: "100%",
            height: "fit-content",
            margin: "auto",
          }}
        >
          <StaticImage
            alt={t("GRAFIK.READING_ARTICLE")}
            src="../images/illustrations/reading_article.png"
            placeholder="blurred"
            layout="constrained"
            width={859}
            loading="lazy"
            style={{ display: "block", zIndex: 2 }}
          />
        </section>
        <section
          className="card wide"
          style={{ padding: 0, width: "100%", height: "fit-content" }}
        >
          <StaticImage
            alt={t("GRAFIK.WATCH")}
            src="../images/illustrations/watch.png"
            placeholder="blurred"
            layout="constrained"
            width={900}
            loading="lazy"
            style={{
              display: "block",
              maxWidth: "900px",
              zIndex: 2,
              margin: "16px auto",
              borderRadius: "15px",
            }}
          />
        </section>
        <section
          className="card wide"
          style={{ padding: 0, width: "100%", height: "fit-content" }}
        >
          <h2>{t("GRAFIK.HEADER_BLENDER")}</h2>
          <StaticImage
            alt={t("GRAFIK.PWA_PERSPECTIVE")}
            src="../images/illustrations/PWA_perspective_closeup.png"
            placeholder="blurred"
            layout="constrained"
            width={900}
            loading="lazy"
            style={{
              display: "block",
              maxWidth: "900px",
              zIndex: 2,
              margin: "16px auto",
              borderRadius: "15px",
            }}
          />
          <StaticImage
            alt={t("GRAFIK.EJAAS_LOGO")}
            src="../images/illustrations/ejaas_logo_perspective.png"
            placeholder="blurred"
            layout="constrained"
            width={900}
            loading="lazy"
            style={{
              display: "block",
              maxWidth: "900px",
              zIndex: 2,
              margin: "16px auto",
              borderRadius: "15px",
            }}
          />
          <StaticImage
            alt={t("GRAFIK.SVG_ARTICLE")}
            src="../images/illustrations/svg_article.png"
            placeholder="blurred"
            layout="constrained"
            width={900}
            loading="lazy"
            style={{
              display: "block",
              maxWidth: "900px",
              zIndex: 2,
              margin: "16px auto",
              borderRadius: "15px",
            }}
          />
        </section>
        <ScrollTopArrow showBelow={1200} />
      </main>
    </>
  )
}

export default Grafik

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
